/**
 * Buttons Component
 * 
 * This component provides a collection of reusable buttons for the application.
 * It includes various button styles, sizes, and states to be used throughout the UI.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
*/


//Define a component for primary button
const PrimaryButton = ({onClick, text, className}) => {
    return (
        <button type="button" className={"btn primaryBtn " + className} onClick={onClick}>{text}</button>
    )
};

export default PrimaryButton;