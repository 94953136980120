/**
 * App.js - Application Entry Point
 * 
 * This file serves as the entry point for our application, initializing key components and settings.
 * It sets up routing, authentication, and other essential configurations.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Dependencies:
 * - [List any external libraries or modules used by this file]
 * 
 * Usage:
 * - This file is typically the main starting point for our application.
 * - It initializes and configures essential components and features.
 * 
 * Example:
 * - [Provide an example of how this file is used in your application]
 * 
 * Note: Customize this comment to include specific details about your application's setup.
*/

import { BrowserRouter } from "react-router-dom";
import AppRoute from "./routes/routes";
import "./Styles/styles.scss";

function App() {
  return (
    <BrowserRouter>
      {/* Wrapped the AppRoute component in the BrowserRouter to provide routing functionality */}
      <AppRoute />
    </BrowserRouter>
  );
}

export default App;
