/**
 * Routes Component
 * 
 * This component defines the application's routing configuration using a routing library.
 * It maps specific routes to corresponding components or views.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Routes:
 * - [List the routes and their associated components/views]
 * 
 * Usage:
 * - [Explain how this component is used to set up routing in the application]
 * 
 * Example:
 * - [Provide an example of defining a route using this component]
 * 
 * Note: Customize this comment to include specific route details or usage guidelines.
*/


import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Homepage from "../Pages/Homepage/Homepage";
import SignIn from "../Pages/Auth/SignIn";
import SignUp from "../Pages/Auth/SignUp";

// Defined a component for handling application routes

const AppRoute = () => {
    //To check whether the user is logged in
    const isLoggedIn = localStorage?.getItem("Email");

    return isLoggedIn ? (
        <Routes>
            {/* Defined a route for the homepage */}
            <Route path="/homepage" element={<Homepage />} />
            <Route path="*" element={<Navigate to="/homepage" />} />
        </Routes>
    ) : (
        <Routes>
            {/* Defined a route for the sign-in page */}
            <Route path="/" element={<SignIn />} />

            {/* Defined a route for the sign-up page */}
            <Route path="/sign-up" element={<SignUp />} />

            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    );
};

export default AppRoute;
