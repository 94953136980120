
/**
 * index.js - Module Entry Point
 * 
 * This file serves as the entry point for the [Module Name] module/component.
 * It initializes and exports the main functionality and components of this module.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Dependencies:
 * - [List any external libraries or modules used by this module]
 * 
 * Usage:
 * - Import and use the components and functions exported by this module in your application.
 * - Customize and extend the functionality as needed.
 * 
 * Example:
 * - Import { ComponentName } from './index.js';
 * - const myComponent = <ComponentName />;
 * 
 * Note: Customize this comment to include specific details about your module/component.
*/

import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import store from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={store}>
    <App />
  </Provider>
);

reportWebVitals();
