/**
 * Homepage
 *
 * This is the main landing page of our application.
 * It serves as the entry point for users and provides an overview of our application's features.
 *
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 *
 * Features:
 * - [ Text to Story Generator,]
 * - [ Text to Image Generator]
 */

import { Radio, Tooltip, Image, Row, Col } from "antd";
import Footer from "../../Components/Footer";
import Header from "../../Components/Header";
import { FcReadingEbook } from "react-icons/fc";
import { TbGhost2 } from "react-icons/tb";
import { FaRunning, FaHeart } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import {
  BsEmojiSmile,
  BsFillRocketTakeoffFill,
  BsHourglassSplit,
} from "react-icons/bs";
import PrimaryButton from "../../Components/Buttons/PrimaryButton";
import { useState } from "react";
import { useDispatch } from "react-redux";
import * as APIService from "../../services/uploadText";
import { Spin } from "antd";
import Typewriter from "typewriter-effect";
import Swal from "sweetalert2";
import { MediaUrl } from "../../Helper";

const Homepage = () => {
  const dispatch = useDispatch();
  const [genre, setGenre] = useState("Comedy");
  const [storyType, setStoryType] = useState("Text");
  const [language, setLanguage] = useState("English");
  const [inputText, setInputText] = useState();
  const [generatedOutput, setGeneratedOutput] = useState();
  const [generatedImage, setGeneratedImage] = useState();
  const [generatedAudio, setGeneratedAudio] = useState();
  const [generatedVideo, setGeneratedVideo] = useState();
  const [loader, setLoader] = useState(false);
  const Email = localStorage?.getItem("Email");

  const onChange = (e) => {
    setGenre(e.target.value);
  };

  const handleStoryType = (e) => {
    setStoryType(e.target.value);
  };

  const handleLanguageType = (e) => {
    setLanguage(e.target.value);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    const words = inputValue.split(" ");
    if (words.length <= 10) {
      setInputText(inputValue);
    }
  };

  const handleFinish = () => {
    setLoader(true);
    let searchString = `Write a ${genre} story on ${inputText}`;
    const formattedData = {
      text: searchString,
      email: Email,
      language: language ? language : "",
    };
    if (storyType === "Text") {
      dispatch(APIService.generateTextOutput(formattedData))
        .then((response) => {
          setLoader(false);
          setGeneratedOutput(response?.data);
        })
        .catch((err) => {
          setLoader(false);
          console.error({ err });
        });
    } else if (storyType === "Image") {
      dispatch(APIService.generateImageOutput(formattedData))
        .then((response) => {
          setLoader(false);
          setGeneratedImage(response?.data);
        })
        .catch((err) => {
          setLoader(false);
          console.error({ err });
        });
    } else if (storyType === "Audio") {
      dispatch(APIService.generateAudioOutput(formattedData))
        .then((response) => {
          setLoader(false);
          setGeneratedOutput(response?.genertedtext);
          setGeneratedAudio(response?.data);
        })
        .catch((err) => {
          setLoader(false);
          console.error({ err });
        });
    } else {
      dispatch(APIService.generateVideoOutput(formattedData))
        .then((response) => {
          setLoader(false);
          setGeneratedVideo(response?.data);
        })
        .catch((err) => {
          setLoader(false);
          console.error({ err });
        });
    }
  };

  // Function to copy text to clipboard
  const copyToClipboard = () => {
    // Create a temporary input element
    const input = document.createElement("input");
    input.value = generatedOutput;

    // Append the input element to the body
    document.body.appendChild(input);

    // Select the text inside the input element
    input.select();

    // Execute the copy command
    document.execCommand("copy");

    // Remove the input element from the DOM
    document.body.removeChild(input);

    Swal.fire({
      title: "Text copied to clipboard",
      icon: "success",
      confirmButtonColor: "#011F4B",
    });
  };

  // Function to download the text as a file
  const downloadTextAsFile = () => {
    // Create a Blob containing the generatedOutput text
    const blob = new Blob([generatedOutput], { type: "text/plain" });

    // Create a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = "generatedOutput.txt"; // You can customize the file name

    // Trigger the click event to start the download
    downloadLink.click();
  };

  const handleDownload = () => {
    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = MediaUrl + "media/" + generatedVideo;
    link.download = "video.mp4"; // Set the desired filename for the downloaded file

    // Trigger a click event on the anchor element to start the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div className="main">
      <Header />
      <div className="homepageWrapper">
        <div className="innerWrapper">
          <div className="container">
            <div className="topHeadingRow">
              <h4>
                AI Story Generator <FcReadingEbook />
              </h4>
            </div>
            <div className="genreRow mt-4">
              <p>Select the genre/theme of the story </p>
              <Radio.Group
                onChange={onChange}
                className="customRadio"
                defaultValue={genre}
              >
                <Radio.Button value="Comedy">
                  <BsEmojiSmile /> Comedy
                </Radio.Button>
                <Radio.Button value="Horror">
                  <TbGhost2 /> Horror
                </Radio.Button>
                <Radio.Button value="Action">
                  <FaRunning /> Action
                </Radio.Button>
                <Radio.Button value="Romance">
                  <FaHeart />
                  Romance
                </Radio.Button>
                <Radio.Button value="Historical">
                  <BsHourglassSplit /> Historical
                </Radio.Button>
                <Radio.Button value="Science">
                  <BsFillRocketTakeoffFill /> Science Fiction
                </Radio.Button>
              </Radio.Group>
            </div>
            <div className="inputRow mt-5">
              <div className="card inputCard">
                <div className="card-header">
                  <h4 className="card-title">
                    Enter the sentences you want to have your story
                  </h4>
                </div>
                <div className="card-body ">
                  <input
                    className="form-control mb-4"
                    placeholder="Enter text here"
                    required
                    onChange={handleInputChange}
                  />
                  <Row>
                    <Col xs={24} md={12}>
                      <div className="typeRow">
                        <label className="typelabel d-block">
                          Select story type
                        </label>
                        <Radio.Group
                          name="storyType"
                          defaultValue={storyType}
                          className=""
                          onChange={handleStoryType}
                        >
                          <Radio value="Text">Text</Radio>
                          <Radio value="Image">Image</Radio>
                          <Radio value="Audio">Audio</Radio>
                          <Radio value="Video">Video</Radio>
                        </Radio.Group>
                      </div>
                    </Col>
                    <Col xs={24} md={12}>
                      {storyType !== "Image" ? (
                        <div className="typeRow">
                          <label className="typelabel d-block">
                            Select your preferred language
                          </label>
                          <Radio.Group
                            name="storyType"
                            defaultValue={language}
                            className=""
                            onChange={handleLanguageType}
                          >
                            <Radio value="English">English</Radio>
                            <Radio value="Hindi">Hindi</Radio>
                          </Radio.Group>
                        </div>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="card-footer text-center py-3">
                  <PrimaryButton onClick={handleFinish} text="Generate Story" />
                </div>
              </div>
            </div>
            {loader && (
              <div className="loaderComponent">
                <Spin size="large" />
                <p>
                  Please wait while we generate a {genre}{" "}
                  {storyType === "Text" ? "Story" : storyType} for you.
                </p>
              </div>
            )}
            {(generatedOutput ||
              generatedAudio ||
              generatedImage ||
              generatedVideo) && (
              <div className="inputRow mt-5">
                <div className="card inputCard">
                  <div className="card-header">
                    <h4 className="card-title">
                      {generatedOutput !== undefined
                        ? "Story based on your input"
                        : storyType === "Text"
                        ? "Story"
                        : storyType}{" "}
                      based on your input
                    </h4>
                    <Tooltip title="Copy">
                      {generatedOutput !== undefined ? (
                        <button
                          type="button"
                          onClick={copyToClipboard}
                          className="btn copyBtn"
                        >
                          <MdContentCopy /> Copy
                        </button>
                      ) : (
                        ""
                      )}
                    </Tooltip>
                  </div>
                  <div className="card-body outputCardBody">
                    {storyType === "Text" ? (
                      <Typewriter
                        options={{
                          strings: [generatedOutput], // Setting the text to be typed
                          autoStart: true, // To Automatically start typing
                          loop: false, // To stop looping of text
                          typeSpeed: 50, // To Adjust the typing speed (milliseconds per character)
                          deleteSpeed: Infinity, //To disable backspacing
                          delay: -1000, // SET TO USE A 1 SECOND DELAY
                          cursorClassName: "cursorSize", // SET TO MY CUSTOM CLASS NAME
                        }}
                        onInit={(typewriter) => {
                          typewriter
                            .callFunction(() => {
                              console.log("String typed out!");
                            })
                            .start();
                        }}
                      />
                    ) : storyType === "Audio" ? (
                      <>
                        {console.info("generatedOutput", generatedOutput)}
                        {generatedAudio !== undefined &&
                        generatedOutput !== undefined ? (
                          <>
                            <Typewriter
                              options={{
                                strings: [generatedOutput], // Setting the text to be typed
                                autoStart: true, // To Automatically start typing
                                loop: false, // To stop looping of text
                                typeSpeed: 50, // To Adjust the typing speed (milliseconds per character)
                                deleteSpeed: Infinity, //To disable backspacing
                                delay: -1000, // SET TO USE A 1 SECOND DELAY
                                cursorClassName: "cursorSize", // SET TO MY CUSTOM CLASS NAME
                              }}
                              onInit={(typewriter) => {
                                typewriter
                                  .callFunction(() => {
                                    console.log("String typed out!");
                                  })
                                  .start();
                              }}
                            />
                            <div className="audioPlayerWrapper">
                              <audio controls>
                                <source
                                  src={MediaUrl + "media/" + generatedAudio}
                                  type="audio/mpeg"
                                />
                                Your browser does not support the audio element.
                              </audio>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                      </>
                    ) : storyType === "Video" ? (
                      <div>
                        <button onClick={handleDownload}>Download Video</button>
                      </div>
                    ) : (
                      <div className="ImageWrapper">
                        <Image src={generatedImage} alt="Not found" />
                      </div>
                    )}
                  </div>
                  {generatedOutput !== undefined ? (
                    <div className="card-footer text-center py-3">
                      <PrimaryButton
                        onClick={downloadTextAsFile}
                        text="Download Story"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Homepage;
