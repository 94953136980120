/**
 * http.js - HTTP Request Handling
 * 
 * This file contains functions and configurations for making HTTP requests to external APIs or endpoints.
 * It encapsulates network communication logic and provides a consistent interface for data retrieval.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Dependencies:
 * - [List any external libraries or modules used by this file]
 * 
 * Usage:
 * - Import and use functions from this file to make HTTP requests in your application.
 * - Customize headers, authentication, and error handling as needed.
 * 
 * Example:
 * - Import { get, post } from './http.js';
 * - const response = await get('/api/data');
 * 
 * Note: Customize this comment to include specific details about your HTTP handling logic.
*/


import axios from "axios";

axios.interceptors.response.use(
  (response) => {
    // console.warn({ response });
    return response;
  },
  (error) => {
    console.warn(error);
    return Promise.reject(error);
  }
);
export default axios;
