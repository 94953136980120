/**
 * Services Module
 *
 * This module provides various services and functions that are used throughout the application.
 * It encapsulates functionality related to data retrieval, processing, and interactions with external resources.
 *
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 *
 * Services:
 * - [List the services provided by this module]
 *
 * Usage:
 * - [Explain how to use the services provided by this module]
 *
 * Example:
 * - [Provide examples of how to invoke the services and handle their results]
 *
 * Note: Customize this comment to include specific service details and usage guidelines.
 */

import Http from "../Http";
import { Url } from "../Helper";

//To generate the text output
export function generateTextOutput(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + `text/`, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

//To generate the Image output
export function generateImageOutput(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + `image/`, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

//To generate Audio output
export function generateAudioOutput(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + `audio/`, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

//To generate V2 Audio output
export function generateV2AudioOutput(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + `v2/audio/`, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}

//To generate Video output
export function generateVideoOutput(value) {
  return (dispatch) =>
    new Promise((resolve, reject) => {
      Http.post(Url + `video/`, value)
        .then((res) => {
          return resolve(res.data);
        })
        .catch((err) => {
          return reject(err);
        });
    });
}
