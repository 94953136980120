
/**
 * store/index.js - Application State Management
 * 
 * This file serves as the entry point for our application's state management using [State Management Library].
 * It defines the store, initializes middleware, and sets up the root reducer.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Dependencies:
 * - [List any external libraries or modules used by this file]
 * 
 * Usage:
 * - Import the store and use it to manage and access application state.
 * - Define actions, reducers, and middleware as needed for state management.
 * 
 * Example:
 * - Import { store } from './store/index.js';
 * - Dispatch actions and access state using the store.
 * 
 * Note: Customize this comment to include specific details about your state management library and setup.
*/

import { applyMiddleware, createStore } from "redux";
import RootReducer from "./reducers";
import ReduxThunk from "redux-thunk";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import hardSet from "redux-persist/lib/stateReconciler/hardSet";
import { composeWithDevTools } from "redux-devtools-extension";

const persistConfig = {
    key: "root",
    storage,
    stateReconciler: hardSet,
};

const persistedReducer = persistReducer(persistConfig, RootReducer);

const store = createStore(
    persistedReducer,
    composeWithDevTools(applyMiddleware(ReduxThunk))
);
persistStore(store);
export default store;