/**
 * Constant Images
 * 
 * This section contains a collection of constant images used throughout the application.
 * These images are typically static and are used for icons, logos, and other graphical elements.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Note: Specify the purpose and usage of each image below.
*/


//Logo
const Logo = require("../Assets/Logo/logo_transparent.png");
const LogoSmall = require("../Assets/Logo/logo_small.png")

export {Logo, LogoSmall};