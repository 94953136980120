/**
 * User Helper Component
 * 
 * This component provides helper functions and utilities related to user management.
 * It includes functions for authentication, user profile management, and other user-related tasks.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Usage:
 * - [Describe how to use this component and its functions]
 * 
 * Note: Add additional comments as needed to describe specific functions or methods.
*/

const UserList = [
    {
        email: "deepak@gmail.com",
        fullname: "Deepak",
        phonenumber: "9024877100",
        password: "Alea@1234",
    },
];


export default UserList;
