/**
 * Header Component
 *
 * This component displays the header for our website.
 * It includes the navigation menu and site branding.
 *
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 */

import { Avatar } from "antd";
import { Link } from "react-router-dom";
import { LogoSmall } from "../../Constant/Images";

const Header = () => {
    const userName = localStorage.getItem("Name");

    // Define a function for initialName
    const getInitials = (userName) => {
        const splits = userName.split(" ", 2);
        if (splits.length < 2) {
            return [userName[0].toUpperCase(), userName[1].toUpperCase()];
        }
        return [splits[0][0], splits[1][0]];
    };

    const initials = getInitials(userName);

    const logout = () => {
        localStorage?.clear();
        window.location.reload();
    };

    return (
        // Header start
        <div className="header">
            <nav className="navbar navbar-expand-lg">
                <div className="container-fluid">
                    <Link className="navbar-brand" to={"/homepage"}>
                        <img src={LogoSmall} className="img-fluid" alt="logo" />
                    </Link>
                    <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavAltMarkup"
                        aria-controls="navbarNavAltMarkup"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                    >
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNavAltMarkup">
                        <ul className="navbar-nav mb-2 mb-lg-0 ms-auto">
                            <li className="nav-item me-5">
                                <Avatar
                                    shape="square"
                                    size="large"
                                    className="avatar primaryAvatar"
                                >
                                    {initials}
                                </Avatar>
                                <span className="userName" id="userName">
                                    {userName}
                                </span>
                            </li>
                            <li className="nav-item">
                                <p className="btn primaryBtn" onClick={logout}>
                                    Log Out
                                </p>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>
        // Header end
    );
};

export default Header;
