/**
 * Sign-Up Page
 * 
 * This page allows new users to create an account.
 * Users can provide their information, create credentials, and complete the registration process.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
*/

import { Col, Row, Form, Input, Button } from "antd";
import { Logo } from "../../Constant/Images";
import { FaEnvelope, FaUnlockAlt, FaUser, FaPhoneAlt } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import UserList from "../../Helpers/users";
import Swal from "sweetalert2";

const SignUp = () => {
    const [form] = Form.useForm();
    const navigate = useNavigate();

    console.info("UserList", UserList);

    //Define a function to handle the submission of form data.
    const onFinish = (values) => {
        const { email, fullname, phonenumber, password } = values;

        const existingUser = UserList.find((user) => user.email === email);

        if (existingUser) {
            Swal.fire({
                title: "User already exists",
                icon: "error",
                confirmButtonText: "Try Again",
                confirmButtonColor: "#0D1117",
            });
        } else {
            const newUser = {
                email,
                fullname,
                phonenumber,
                password,
            };
            UserList.push(newUser);

            Swal.fire({
                title: "Registration Successful",
                icon: "success",
                confirmButtonText: "OK",
                confirmButtonColor: "#0D1117",
            });
            navigate("/");
        }
    };

    //Define a function to display an error message for failed form submissions.
    const onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };

    return (
        // Auth wrapper Start
        <div className="authWrapper">
            <div className="innerWrapper">
                <Row className="h-100">
                    <Col xs={24} md={10} lg={14}>
                        {/* Logo section start */}
                        <div className="logoSection">
                            <div className="logoInner">
                                <img src={Logo} className="img-fluid" alt="logo" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} md={14} lg={10}>
                        {/* Form section start */}
                        <div className="formSection">
                            <div className="formInner">
                                <div className="formTitle">
                                    <h3>Welcome to VISAI</h3>
                                </div>
                                <Form
                                    form={form}
                                    name="login_form"
                                    className="authForm"
                                    onFinish={onFinish}
                                    onFinishFailed={onFinishFailed}
                                    autoComplete="off"
                                    layout="vertical"
                                >
                                    <Form.Item
                                        label="Full Name"
                                        name="fullname"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your full name!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<FaUser />}
                                            className="form_control"
                                            placeholder="Full Name"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your email!",
                                            },
                                            {
                                                type: "email",
                                                message: "Please enter a valid email address!",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<FaEnvelope />}
                                            className="form_control"
                                            placeholder="Email"
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Phone Number"
                                        name="phonenumber"
                                        type="tel"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your phone number!",
                                            },
                                            {
                                                pattern: /^[0-9]+$/,
                                                message:
                                                    "Phone number must contain only numeric digits.",
                                            },
                                        ]}
                                    >
                                        <Input
                                            prefix={<FaPhoneAlt />}
                                            className="form_control"
                                            placeholder="Phone Number"
                                            minLength={10}
                                            maxLength={10}
                                        />
                                    </Form.Item>
                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your password!",
                                            },
                                            {
                                                pattern:
                                                    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
                                                message:
                                                    "Password must be at least 8 characters long and include at least one letter, one number, and one special character.",
                                            },
                                        ]}
                                    >
                                        <Input.Password
                                            prefix={<FaUnlockAlt />}
                                            className="form_control"
                                            placeholder="Password"
                                        />
                                    </Form.Item>
                                    <Form.Item className="mt-6 text-center">
                                        <Button htmlType="submit" className="submitBtn">
                                            Sign Up
                                        </Button>
                                    </Form.Item>
                                </Form>
                                <div className="orTextRow">
                                    <p className="orText">or</p>
                                    <p className="bottomText">
                                        Already have an account.{" "}
                                        <Link to={"/"} className="linkText">
                                            Sign In
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        </div>
        //Auth wrapper end
    );
};

export default SignUp;
