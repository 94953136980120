/**
 * Footer Component
 * 
 * This component represents the footer section of our application.
 * It typically includes important links, copyright information, and other footer content.
 * 
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 * 
 * Usage:
 * - [Explain how to include and use this component in your application]
 *  
 * Note: Customize this comment to include specific information about your footer component.
 */


import { Col, Row } from "antd";

const Footer = () => {
	return (
		// Footer wrapper start
		<div className="footerWrapper">
			<div className="footerInner">
				<div className="container-fluid">
					<Row gutter={16}>
						<Col xs={24} md={12} lg={12} >
							<div className="footerContent">
								<p>VISAI © 2023 Copyright, All rights reserved.</p>
							</div>
						</Col>
						<Col xs={24} md={12} lg={12}>
							<div className="footerContentRight">
								<p>Designed & Develpoed by Decoders</p>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</div>
		// Footer wrapper end
	);
};

export default Footer;
