/**
 * Url Helper page
 *
 * This page allows us to create a helper for the Url to be used all over the code.
 *
 * Author: [Team-Decoders]
 * Date: [23 Sep, 2023]
 */

// Live URL
const Url = "https://aleanshackathon23c-backend.aleaspeaks.com/";
const MediaUrl = "https://aleanshackathon23c-backend.aleaspeaks.com/";

//Local URl
//For Text generation
// const Url = "http://127.0.0.1:8000/app/";
//For Media
// const MediaUrl = "http://127.0.0.1:8000/";

//Exporting End Point
export { Url, MediaUrl };
